import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import { addMinutes } from "date-fns";
import actions from "../../actions";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import ColorIndexManager from "utils/ColorIndexManager";
import StartDemoCallConfirmDialog from "./StartDemoCallConfirmDialog";
import WeeklyCalendarSessions from "../WeeklyCalendarSessions";
import ScheduleUtilityDrawer from "./ScheduleUtilityDrawer";

import {
  isClinician,
  getUserId,
  getClinicianCalendarCalls,
  getClinicianCalendarCallsLoading,
  getUserPermissionsList,
  rescheduleVideoDetails,
  setDemoCallLoading,
  setDemoCallSuccess,
  getCurrentClinicianId,
  scheduleCallLoading,
  scheduleCallSuccess,
  getWeekStartDate,
  getShowWeekCanceledCalls,
  getCompletingOffPlatform,
  getCompleteOffPlatformSuccess,
  getUser,
  getClinicianNonWorkingHours,
  getClinicianCalendarPrivateBlocks,
} from "../../selectors";
import { Box } from "@mui/material";
import { roundDownToNearest15 } from "components/EventScheduling/EventSchedulingUtils";

const mapStateToProps = (state) => ({
  user: getUser(state),
  isClinician: isClinician(state),
  clinicianUserId: getUserId(state),
  calendarCalls: getClinicianCalendarCalls(state),
  calendarCallsLoading: getClinicianCalendarCallsLoading(state),
  nonWorkingHours: getClinicianNonWorkingHours(state),
  calendarPrivateBlocks: getClinicianCalendarPrivateBlocks(state),
  userPermissions: getUserPermissionsList(state),
  rescheduleVideoDetails: rescheduleVideoDetails(state),
  setDemoCallLoading: setDemoCallLoading(state),
  setDemoCallSuccess: setDemoCallSuccess(state),
  clinicianId: getCurrentClinicianId(state),
  scheduleCallLoading: scheduleCallLoading(state),
  scheduleCallSuccess: scheduleCallSuccess(state),
  weekStartDate: getWeekStartDate(state),
  showWeekCanceledCalls: getShowWeekCanceledCalls(state),
  completingOffPlatform: getCompletingOffPlatform(state),
  completeOffPlatformSuccess: getCompleteOffPlatformSuccess(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkAuthState: actions.checkAuthState,
      setPageDetails: actions.setPageDetails,
      setOneTimeVideoInfo: actions.setOneTimeVideoInfo,
      setDemoCall: actions.setDemoCall,
      clearVideoInfo: actions.clearVideoInfo,
      getClinicianWeeklySessions: actions.getUserCalendarSessions,
      setWeekStartDate: actions.setWeekStartDate,
      setWeekEndDate: actions.setWeekEndDate,
      setShowWeekCanceledCalls: actions.setShowWeekCanceledCalls,
    },
    dispatch
  );

class ClinicianSessionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleDemoOpen: false,
      startDemoOpen: false,
      selectedClient: null,
      filteredList: [],
      searchInput: "",
      showScheduleUtilityDrawer: true,
      peopleFilters: [],
      selectedServices: [],
      showSupervisoryEvents: false,
      showTentativeEvents: false,
    };
    this.clientListRef = [];

    // Color index manager for the calendar
    this.colorIndexManager = React.createRef();
  }

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Schedule",
      currentPage: "schedule",
      menu: "clinicianSchedule",
    });

    if (this.props.clinicianUserId && !this.props.weekStartDate) {
      this.setCurrentWeek(new Date());
    }

    // Initialize the color index manager
    this.colorIndexManager.current = new ColorIndexManager();
  }

  componentDidUpdate(prevProps, prevState) {
    // Set the current week if necessary based on clinician user ID and week start date
    if (!prevProps.clinicianUserId && this.props.clinicianUserId && !this.props.weekStartDate) {
      this.setCurrentWeek(new Date());
    }

    // Handle video details setup and redirection for demo calls
    if (
      prevProps.setDemoCallLoading &&
      !this.props.setDemoCallLoading &&
      this.props.setDemoCallSuccess
    ) {
      let roomId = uuidv4();
      const details = {
        videoInfo: this.props.rescheduleVideoDetails,
        roomId,
        videoKey: this.props.rescheduleVideoDetails.videoKey,
      };
      this.props.setOneTimeVideoInfo(details);
      this.toggleStartDemoCallDialog();
      this.props.history.push(`/video/${details.videoKey}`);
    }

    const sessionUpdateNeeded =
      (prevProps.scheduleCallLoading !== this.props.scheduleCallLoading &&
        this.props.scheduleCallSuccess) ||
      (prevProps.completingOffPlatform !== this.props.completingOffPlatform &&
        this.props.completeOffPlatformSuccess) ||
      prevProps.showWeekCanceledCalls !== this.props.showWeekCanceledCalls ||
      prevState.peopleFilters !== this.state.peopleFilters ||
      prevState.selectedServices !== this.state.selectedServices ||
      prevState.showSupervisoryEvents !== this.state.showSupervisoryEvents ||
      prevState.showTentativeEvents !== this.state.showTentativeEvents;

    if (sessionUpdateNeeded) {
      this.fetchClinicianWeeklySessions();
    }
  }

  fetchClinicianWeeklySessions = () => {
    const sessionFilters = {
      currentUserId: this.props.clinicianUserId,
      peopleFilters: this.state.peopleFilters,
      selectedServices: this.state.selectedServices,
      showSupervisoryEvents: this.state.showSupervisoryEvents,
      showTentativeEvents: this.state.showTentativeEvents,
    };

    this.props.getClinicianWeeklySessions(sessionFilters);
  };

  setCurrentWeek = (date, type) => {
    let firstDay = moment(date)
      .startOf(type ?? "Week")
      .format();
    let lastDay = moment(date)
      .endOf(type ?? "Week")
      .format();
    this.props.setWeekStartDate(firstDay);
    this.props.setWeekEndDate(lastDay);
    this.fetchClinicianWeeklySessions();
  };

  getClientSessions = (id) => {
    this.setState({ selectedClient: id }, () => {
      this.fetchClinicianWeeklySessions();
    });
  };

  selectFoundClient = (id) => {
    this.setState({ searchInput: "", selectedClient: id }, () => {
      this.autoScrollClient(id);
      this.fetchClinicianWeeklySessions();
    });
  };

  autoScrollClient = (id) => {
    this.clientListRef[id].scrollIntoView(true);
  };

  toggleStartDemoCallDialog = () => {
    this.setState({ startDemoOpen: !this.state.startDemoOpen });
  };

  startDemoCall = async () => {
    const { clinicianUserId } = this.props;
    // We want to have a time that is available in the new schedule page
    const startDate = roundDownToNearest15(new Date());
    let videoDetails = {
      startDate,
      endDate: addMinutes(startDate, 30),
      clinicianUserId,
    };
    this.props.clearVideoInfo();
    this.props.setDemoCall(videoDetails);
  };

  onOpenDemoCallDialog = () => {
    this.setState({ scheduleDemoOpen: true });
  };

  onCloseDemoCallDialog = () => {
    this.setState({ scheduleDemoOpen: false });
    this.setCurrentWeek(new Date());
  };

  handleSwitchChange = () => {
    const { showWeekCanceledCalls } = this.props;
    this.props.setShowWeekCanceledCalls(!showWeekCanceledCalls);
  };

  setUserScheduleColorMap = (value) => {
    this.setState({ userScheduleColorMap: value });
  };

  setPeopleFilters = (value) => {
    this.setState({ peopleFilters: value });
  };

  setSelectedServices = (value) => {
    this.setState({ selectedServices: value });
  };

  toggleScheduleUtilityDrawer = () => {
    this.setState({
      showScheduleUtilityDrawer: !this.state.showScheduleUtilityDrawer,
    });
  };

  render() {
    const { setDemoCallLoading } = this.props;
    const { startDemoOpen, showScheduleUtilityDrawer } = this.state;
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{
          overflow: "hidden",
          maxHeight: "100%",
        }}
      >
        <ScheduleUtilityDrawer
          toggleScheduleUtilityDrawer={this.toggleScheduleUtilityDrawer}
          showScheduleUtilityDrawer={showScheduleUtilityDrawer}
          toggleStartDemoCallDialog={this.toggleStartDemoCallDialog}
          getClientSessions={this.getClientSessions}
          selectFoundClient={this.selectFoundClient}
          clientListRef={this.clientListRef}
          onOpenDemoCallDialog={this.onOpenDemoCallDialog}
          handleSwitchChange={this.handleSwitchChange}
          colorIndexManager={this.colorIndexManager.current}
          selectedServices={this.state.selectedServices}
          setSelectedServices={this.setSelectedServices}
          peopleFilters={this.state.peopleFilters}
          setPeopleFilters={this.setPeopleFilters}
          handleToggleShowSupervisoryEvents={() => {
            this.setState({
              showSupervisoryEvents: !this.state.showSupervisoryEvents,
            });
          }}
          handleToggleTentativeEvents={() => {
            this.setState({
              showTentativeEvents: !this.state.showTentativeEvents,
            });
          }}
          userId={this.props.user.id}
          {...this.props}
          {...this.state}
        />

        <WeeklyCalendarSessions
          toggleScheduleUtilityDrawer={this.toggleScheduleUtilityDrawer}
          setCurrentWeek={this.setCurrentWeek}
          calendarCalls={this.props.calendarCalls}
          history={this.props.history}
          isAdmin={false}
          clinicianSessionsPageAction={false}
          weekStartDate={this.props.weekStartDate}
          toggleStartDemoCallDialog={this.toggleStartDemoCallDialog}
          colorIndexManager={this.colorIndexManager.current}
          fetchClinicianWeeklySessions={this.fetchClinicianWeeklySessions}
        />

        <StartDemoCallConfirmDialog
          startDemoOpen={startDemoOpen}
          toggleStartDemoCallDialog={this.toggleStartDemoCallDialog}
          startDemoCall={this.startDemoCall}
          setDemoCallLoading={setDemoCallLoading}
        />
      </Box>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClinicianSessionsContainer))
);
