import { makeStyles } from "@mui/styles";

/**
 * Generates the styles used in the component.
 *
 * @param {Object} theme - The theme object provided by the ThemeProvider.
 * @returns {Object} - The styles object.
 */
const useStyles = makeStyles((theme) => ({
  // Styles for days of the week buttons
  ...theme.bigCalendarCustomStyle,
  daysOfWeek: {
    "& button": {
      minWidth: "45px",
    },
  },
  // Custom styles for the calendar component
  calendarTheme: {
    ...theme.bigCalendarCustomStyle,

    // Building event styles
    "& .building": {
      borderStyle: "dashed",
      borderWidth: "1.5px",
      pointerEvents: "none",
      cursor: "default",
    },

    // Proposed event styles
    "& .proposed": {
      color: theme.palette.primary.main,
      backgroundColor: "#FFF",
      backgroundSize: "6px 6px",
      "&:hover": { backgroundColor: "#F4ECFB", backgroundImage: "none" },
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrast,
        outline: "none",
      },
    },

    // Conflicted event styles
    "& .conflicted": {
      border: `1.5px dashed ${theme.palette.error.main}`,
      backgroundColor: theme.palette.error.background,
      pointerEvents: "none",

      "& .event-color-bar": {
        color: theme.palette.error.main,
      },

      "& .event-client-name": {
        color: theme.palette.error.main,
      },
    },
  },

  // Styles for the selected date display
  selectedDate: {
    borderRadius: "100px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrast,
    padding: theme.spacing(0, 1),
    minWidth: "22px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
