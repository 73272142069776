import React from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useTheme } from "@mui/styles";

import "react-big-calendar/lib/css/react-big-calendar.css";
import ANCalendarEvent from "./ANCalendarEvent";

const localizer = momentLocalizer(moment);

export default function ANCalendar({
  components,
  view,
  colorIndexManager,
  nonWorkingHours = [],
  ...props
}) {
  const theme = useTheme();

  const formats = {
    dayFormat: (date, culture, localizer) => localizer.format(date, "ddd D", culture),
    dayRangeHeaderFormat: ({ end }, culture, localizer) =>
      localizer.format(end, "MMM yyyy", culture),
    timeGutterFormat: "h a",
  };

  const CustomEvent = ({ event }) => <ANCalendarEvent event={event} view={view} />;

  const CustomEventWrapper = (eventWrapperProps) => {
    // Function to extract percentage from string, used to remove the calc() from the width and left properties.
    // Original string: "calc(8.333333333333334% - 0px)", returns "8.333333333333334%".
    // We do this here to remove the padding from events that `dayLayoutAlgorithm="no-overlap"`
    // adds by default, we want them to 'touch' each other.
    function extractPercentage(str = "", parseToNumber = false) {
      const match = str.match(/(\d+(\.\d+)?%)/);

      if (!match) return str;
      return parseToNumber ? Number.parseFloat(match[0]) : match[0];
    }

    const { call_duration } = eventWrapperProps.event;
    // Fix the height of the event block if the call duration is less than 40 minutes.
    let fixHeight = 0;
    if (call_duration < 40) fixHeight = 0.2;
    if (call_duration < 30) fixHeight = 0.33;

    let style = {
      padding: "0px 10px 2px 10px",
    };

    if (view !== Views.MONTH) {
      const height = `${extractPercentage(eventWrapperProps.children.props.style.height, true) + fixHeight}%`;

      style = {
        padding: theme.spacing(3, 1),
        position: "absolute",
        height,
        top: `${eventWrapperProps?.style?.top}%`,
        xOffset: `${eventWrapperProps?.style?.xOffset}%`,
        left: extractPercentage(eventWrapperProps.children.props.style.left),
        width: extractPercentage(eventWrapperProps.children.props.style.width),
      };
    }
    return <div style={style}>{eventWrapperProps.children}</div>;
  };

  const eventStyle = (e) => {
    const isExpired = new Date(e.end) < new Date();
    // If a client is selected, use its color, otherwise use the clinician's color.
    const colorSelectedClient = colorIndexManager?.find(e.client_id);
    const eventColorIndex = colorSelectedClient ?? colorIndexManager?.get(e.clinician_id) ?? "none";

    // If the event is a building event, add the building class to the event.
    const buildingEvent = e?.call_type === "BUILDING" ? "building" : "";

    return {
      style: {
        padding: e.call_duration < 30 || view === Views.MONTH ? "2px 8px" : theme.spacing(3),
      },
      className: `h100 event-color-${eventColorIndex} ${buildingEvent} ${
        isExpired ? "expired" : ""
      } ${e.canceled || e.no_show ? "red-text" : ""} ${e.googleEvent ? "private" : ""} ${e.is_tentative ? "tentative" : ""}`,
    };
  };

  const slotPropGetter = (date) => {
    for (let hours of nonWorkingHours) {
      let start = new Date(hours.startDateTime);
      let end = new Date(hours.endDateTime);
      if (date >= start && date < end) return { className: "non-working-hours" };
    }
  };

  return (
    <Calendar
      localizer={localizer}
      formats={formats}
      components={{
        event: CustomEvent,
        eventWrapper: CustomEventWrapper,
        ...components,
      }}
      view={view}
      eventPropGetter={eventStyle}
      dayLayoutAlgorithm="no-overlap"
      slotPropGetter={slotPropGetter}
      startAccessor="start"
      endAccessor="end"
      timeslots={1}
      step={60}
      {...props}
    >
      ANCalendar
    </Calendar>
  );
}
